
import "antd/dist/antd.min.css";
import LandingPage from "./View/Landing-Page/LandingPage";

import "./App.css"

function App() {
  return (
    <LandingPage></LandingPage>
  );
}

export default App;
