import React from "react";
import Lottie from "lottie-react"

import Logo from "../../assets/logos/MELOCOTON_2_logo.png";
import whatsappLogo from "../../assets/logos/whatsapp-icon.svg"
import instagramLogo from "../../assets/logos/instagram-icon.svg"
import facebookLogo from "../../assets/logos/facebook-icon.svg"
import styles from "./LandingPage.module.css";
import cookingAnimation from "../../assets/lottie/cooking-loop-animation.json"
import { INSTAGRAM_BUTTON, WHATSAPP_BUTTON, FACEBOOK_BUTTON } from "../../Helpers/StringResourcesHelper";
import { FACEBOOK_URL, INSTAGRAM_URL, WHATSAPP_URL } from "../../Helpers/UrlResourcesHelper";

const MainPage = (props) => {

  const socialMediaClickHandler = (socialMedia) => {
    switch (socialMedia) {
      case INSTAGRAM_BUTTON:
        window.open(INSTAGRAM_URL)
        break;
      case WHATSAPP_BUTTON:
        window.open(WHATSAPP_URL)
        break;
      case FACEBOOK_BUTTON:
        window.open(FACEBOOK_URL)
        break;
      default:
        break;
    }
  }

  return (
    <>
      <div className={styles.webApp}>
        {/***** Header *****/}
        <div className={styles.headerLogoContainer}>
          <img className={styles.headerLogo} src={Logo} alt="Melocoton Bakery Logo" />
        </div>
        {/***** Body *****/}
        <div id={styles.headerRow} className="row justify-content-center" style={{ margin: 0 }}>
          <div className={styles.headerContainer + " col-auto col-sm-6  col-lg-auto"}>
            <div className={styles.headerSection}>
              <h3 className={styles.smallHeader}>¡Bienvenido!</h3>
              <h3 className={styles.header}>¡Bienvenido a <br />Melocoton Bakery!</h3>
            </div>
            <Lottie className={styles.lottie} animationData={cookingAnimation}></Lottie>
            <div className={styles.headerDescription}>
              <p>Aún estoy ordenando mi estación de trabajo. Mientras tanto...</p>
              <p><b>¡Sígueme en mis diferentes redes sociales!</b></p>
            </div>
          </div>
          <div id={styles.socialMediaContainer} className="col-auto col-sm-6 col-lg-auto">
            <div className={styles.container + " row justify-content-center"} style={{ padding: "3em 3em 2em 3em" }}>
              <div className={styles.whatsAppLogo + " col-4 d-flex justify-content-end"}>
                <img src={whatsappLogo} alt="Whatsapp logo" onClick={() => {
                  socialMediaClickHandler(WHATSAPP_BUTTON)
                }}></img>
              </div>
              <div className={styles.instagramLogo + " col-4 d-flex justify-content-center"}>
                <img src={instagramLogo} alt="Instagram logo" onClick={() => {
                  socialMediaClickHandler(INSTAGRAM_BUTTON)
                }}></img>
              </div>
              <div className={styles.facebookLogo + " col-4 d-flex justify-content-start"}>
                <img src={facebookLogo} alt="Facebook logo" onClick={() => {
                  socialMediaClickHandler(FACEBOOK_BUTTON)
                }}></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainPage;
